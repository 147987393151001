<template>
  <div class="personal-header">
    <HeaderBlock />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'settings'" />
    <div class="block-right page-settings">
      <a @click="$router.push({ name: 'personal' })" class="back">
        {{ backProfile }}
      </a>
      <PersonalSettingsBlock :profile="profile" />
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import PersonalSettingsBlock from "@/components/PersonalSettingsBlock.vue";
import "@/assets/css/personal.css";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    PersonalSettingsBlock,
  },
  data() {
    return {
      backProfile: Locale.TEXTS.backProfile,
    };
  },
  created() {
    document.title = Locale.TEXTS.profileTitle;
  },
};
</script>
