<template>
  <HeaderBlock />
  <section class="simple-section page-section">
    <div v-if="simple">
      <EditorBlock :page="simple" :isProduct="false" :sourcePage="13" />
      <div v-if="slug === 'partner-request'" class="partner-request">
        <button @click.stop="partnerRequest">Стать партнером</button>
        <span class="agree">
          Заполняя форму вы соглашаетесь с
          <a href="/policy" target="_blank">политикой конфиденциальности</a>
          и
          <a href="/offerta-affiliate-program" target="_blank">
            условиями партнерской программы
          </a>
        </span>
      </div>
    </div>
  </section>
  <FooterBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="'Войдите или зарегистрируйтесь, чтобы стать партнером'"
  />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="true"
    :isLastName="true"
  />
  <SurveyBlock v-if="isOpenSurvey" :profile="profile" />
  <ThankBlock v-if="isOpenThank" />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import EditorBlock from "@/components/EditorBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import SurveyBlock from "@/components/SurveyBlock.vue";
import ThankBlock from "@/components/ThankBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    EditorBlock,
    FooterBlock,
    AuthRegBlock,
    ProfileNameBlock,
    SurveyBlock,
    ThankBlock,
  },
  data() {
    return {
      slug: null,
      simple: null,
      isOpenAuth: false,
      isOpenProfileName: false,
      isOpenSurvey: false,
      isOpenThank: false,
      locale: Locale,
    };
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
      this.partnerRequest();
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27 && !that.isCheckout) {
        that.isOpenAuth = false;
        that.isOpenProfileName = false;
        that.isOpenSurvey = false;
        that.isOpenThank = false;
        that.isOpenPopup(false);
      }
    });
  },
  async created() {
    try {
      this.slug = this.$route.params.path_page;
      const result = await api.shop.getShopPageFromPath(this.slug);
      let page = result["data"]["results"][0];
      if (page["simple_page"]) this.simple = page["simple_page"];
      else window.location.href = "/";
      document.title = page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/";
    }
  },
  methods: {
    async partnerRequest() {
      if (this.authToken) {
        try {
          let data = {
            partner_id: this.profile.id,
          };
          await api.profile.postPartnerRequest(data);
        } catch (error) {
          console.log(error);
        }
        this.isOpenProfileName = true;
      } else {
        this.isOpenAuth = true;
      }
    },
  },
  watch: {
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenProfileName(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenSurvey(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenThank(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
};
</script>
