<template>
  <div @click="(sortOpen = false), (filterOpen = false)">
    <div class="feedback-header">
      <HeaderBlock />
    </div>
    <section class="feedback-section page-section">
      <a class="page-back" :href="'/catalog/' + slug">
        <span>{{ product.headline_preview }}</span>
      </a>
      <h1>{{ locale.TEXTS.feedbackH1 }} {{ product.headline_preview }}</h1>
      <div class="page-content">
        <div class="page-left">
          <div class="product-feedback">
            <span class="eval">{{ evalFeedback }}</span>
            <div class="rating">
              <span class="stars" v-html="starsFeedback"></span>
              <span class="text" v-if="locale.LOCALE === 'ru'">
                На основании {{ countFeedback }} отзывов
              </span>
              <span class="text" v-else>
                Based on {{ countFeedback }} feedback
              </span>
              <button
                v-if="locale.LOCALE === 'ru'"
                @click="openAddFeedbackBlock"
              >
                Оставить отзыв
              </button>
            </div>
          </div>
          <div class="page-filters">
            <div
              class="sort"
              @click.stop="
                sortOpen === true ? (sortOpen = false) : (sortOpen = true)
              "
            >
              <span class="key">{{ locale.TEXTS.filterDisplay }}:</span>
              <span class="value">
                {{ locale.TEXTS.feedbackFilterValue }} {{ sortValue }}
              </span>
              <div v-if="sortOpen" class="block">
                <span
                  :class="{
                    active: sortValue === locale.TEXTS.feedbackFilterNew,
                  }"
                  @click.stop="
                    (sortValue = locale.TEXTS.feedbackFilterNew),
                      (sortOpen = false)
                  "
                >
                  {{ locale.TEXTS.feedbackNewText }}
                </span>
                <span
                  :class="{
                    active: sortValue === locale.TEXTS.feedbackFilterUseful,
                  }"
                  @click.stop="
                    (sortValue = locale.TEXTS.feedbackFilterUseful),
                      (sortOpen = false)
                  "
                >
                  {{ locale.TEXTS.feedbackUsefulText }}
                </span>
              </div>
            </div>
            <div class="filter">
              <span
                class="value"
                @click.stop="
                  filterOpen === true
                    ? (filterOpen = false)
                    : (filterOpen = true)
                "
              >
                {{ locale.TEXTS.filterText }}
              </span>
              <div v-if="filterOpen" class="block">
                <label>
                  <input type="checkbox" v-model="checkedWithImages" />
                  <div class="text">
                    {{ locale.TEXTS.onlyWithImages }} ({{ countWithImages }})
                  </div>
                </label>
                <span>{{ locale.TEXTS.feedbackEval }}:</span>
                <label>
                  <input type="checkbox" v-model="checkedCountEval5" />
                  <div class="text">
                    5
                    <div>
                      <span :style="{ width: countEval['5']['width'] + '%' }">
                      </span>
                    </div>
                    {{ countEval["5"]["count"] }}
                  </div>
                </label>
                <label>
                  <input type="checkbox" v-model="checkedCountEval4" />
                  <div class="text">
                    4
                    <div>
                      <span :style="{ width: countEval['4']['width'] + '%' }">
                      </span>
                    </div>
                    {{ countEval["4"]["count"] }}
                  </div>
                </label>
                <label>
                  <input type="checkbox" v-model="checkedCountEval3" />
                  <div class="text">
                    3
                    <div>
                      <span :style="{ width: countEval['3']['width'] + '%' }">
                      </span>
                    </div>
                    {{ countEval["3"]["count"] }}
                  </div>
                </label>
                <label>
                  <input type="checkbox" v-model="checkedCountEval2" />
                  <div class="text">
                    2
                    <div>
                      <span :style="{ width: countEval['2']['width'] + '%' }">
                      </span>
                    </div>
                    {{ countEval["2"]["count"] }}
                  </div>
                </label>
                <label>
                  <input type="checkbox" v-model="checkedCountEval1" />
                  <div class="text">
                    1
                    <div>
                      <span :style="{ width: countEval['1']['width'] + '%' }">
                      </span>
                    </div>
                    {{ countEval["1"]["count"] }}
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            v-for="item in feedback"
            :key="item"
            class="product-feedback-item"
          >
            <ProductFeedbackItemBlock
              :review="item"
              :valueAllowTouchMove="valueAllowTouchMove"
              :slidesPerView="slidesPerView"
              :spaceBetween="spaceBetween"
            />
            <div :class="{ open: item.isOpen }">
              <div class="answer-text" v-if="item.isOpen">
                {{ item.answer_text }}
              </div>
              <span
                v-if="item.isOpen && item.answer_text"
                class="show-answer"
                @click="item.isOpen = false"
              >
                {{ locale.TEXTS.feedbackAnswerHide }}
              </span>
              <span
                v-else-if="item.answer_text"
                class="show-answer"
                @click="item.isOpen = true"
              >
                {{ locale.TEXTS.feedbackAnswerShow }}
              </span>
              <span
                v-if="item.count_likes > 0 && locale.LOCALE === 'ru'"
                class="likes"
                :class="{ active: item.isMyLike }"
                @click="clickFeedbackLike(item)"
              >
                <img
                  v-if="item.isMyLike"
                  src="@/assets/images/shop-like-active.svg"
                />
                <img v-else src="@/assets/images/shop-like-gray.svg" />
                {{ item.count_likes }}
              </span>
              <span
                v-else-if="!item.isMy && locale.LOCALE === 'ru'"
                class="likes"
                @click="clickFeedbackLike(item)"
              >
                <img src="@/assets/images/shop-like-gray.svg" />
                Полезный отзыв?
              </span>
              <span v-else class="likes">
                <img src="@/assets/images/shop-like-gray.svg" />
                {{ item.count_likes }}
              </span>
            </div>
          </div>
        </div>
        <ProductInfoPageRightBlock
          v-if="product.id"
          :product="product"
          :info="'feedback'"
        />
      </div>
    </section>
  </div>
  <ErrorTooltipBlock
    v-if="errorTooltip"
    :header="'Ошибка'"
    :text="'Вы не можете поднять рейтинг своего отзыва. Это могут сделать только другие пользователи.'"
  />
  <FooterBlock />
  <AuthRegBlock
    v-if="isOpenAuth && locale.LOCALE === 'ru'"
    :authText="authText"
  />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="false"
    :isPartner="false"
    :isLastName="false"
  />
  <NotOrderBlock v-if="isOpenNotOrder" />
  <ExistFeedbackBlock v-if="isOpenExistFeedback" />
  <AddFeedbackBlock
    v-if="isOpenAddFeedback && locale.LOCALE === 'ru'"
    :product="product"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import NotOrderBlock from "@/components/NotOrderBlock.vue";
import ExistFeedbackBlock from "@/components/ExistFeedbackBlock.vue";
import AddFeedbackBlock from "@/components/AddFeedbackBlock.vue";
import ProductFeedbackItemBlock from "@/components/ProductFeedbackItemBlock.vue";
import ProductInfoPageRightBlock from "@/components/ProductInfoPageRightBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    AuthRegBlock,
    ProfileNameBlock,
    NotOrderBlock,
    ExistFeedbackBlock,
    AddFeedbackBlock,
    ProductFeedbackItemBlock,
    ProductInfoPageRightBlock,
    ErrorTooltipBlock,
  },
  data() {
    return {
      locale: Locale,
      isOpenAuth: false,
      isOpenProfileName: false,
      isOpenNotOrder: false,
      isOpenExistFeedback: false,
      isOpenAddFeedback: false,
      product: Object,
      slug: "",
      evalFeedback: 0,
      countFeedback: 0,
      starsFeedback: "",
      allFeedback: [],
      feedback: [],
      slidesPerView: 5,
      spaceBetween: 10,
      sortOpen: false,
      sortValue: Locale.TEXTS.feedbackFilterNew,
      filterOpen: false,
      countWithImages: 0,
      countEval: {
        1: {
          count: 0,
          width: 0,
        },
        2: {
          count: 0,
          width: 0,
        },
        3: {
          count: 0,
          width: 0,
        },
        4: {
          count: 0,
          width: 0,
        },
        5: {
          count: 0,
          width: 0,
        },
      },
      checkedWithImages: false,
      checkedCountEval1: false,
      checkedCountEval2: false,
      checkedCountEval3: false,
      checkedCountEval4: false,
      checkedCountEval5: false,
      valueAllowTouchMove: true,
      isExistFeedback: false,
      errorTooltip: null,
    };
  },
  created() {
    if (window.innerWidth >= 1200) {
      this.valueAllowTouchMove = false;
    }
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_catalog;
      const result = await api.shop.getShopPageFromPath(this.slug);
      let page = result["data"]["results"][0];
      if (page["product"]) this.product = page["product"];
      else window.location.href = "/catalog";
      document.title = "Отзывы о продукте " + page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/catalog";
    }
    try {
      const result = await api.shop.getProductFeedback(this.product.id, 50);
      this.countFeedback = result["data"]["results"].length;
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        this.evalFeedback += item["eval"];
        let avatar = null;
        let isMy = false;
        let name = item["client_name"];
        if (item["client_avatar"]) {
          avatar = item["client_avatar"]["image_object"];
        }
        if (item["client_info"]) {
          if (item["client_info"]["avatar"]) {
            avatar = item["client_info"]["avatar"]["image_object"];
          }
          name = item["client_info"]["first_name"];
          if (item["client_info"]["id"] === this.profile.id) {
            isMy = true;
          }
        }
        let date = item["created_at"].split("T")[0].split("-");
        let text = item["text_edited"];
        if (!text) text = item["text_origin"];
        let images = item["images"];
        let isMyLike = false;
        if (this.authToken && item["user_likes"].includes(this.profile.id)) {
          isMyLike = true;
        }
        this.allFeedback.push({
          id: item["id"],
          avatar: avatar,
          name: name,
          date: date[2] + "." + date[1] + "." + date[0],
          content: text,
          images: images,
          eval: item["eval"],
          count_likes: item["count_likes"],
          answer_text: item["answer_text"],
          isOpen: false,
          stars: this.getStars(item["eval"]),
          isMy: isMy,
          isMyLike: isMyLike,
        });
        if (item["client"] === this.profile.id) this.isExistFeedback = true;
        this.countEval[item["eval"]]["count"] += 1;
        if (images.length > 0) this.countWithImages += 1;
        this.feedback = this.allFeedback;
      }
      let maxEval = 1;
      if (this.countEval[1]["count"] > maxEval) {
        maxEval = this.countEval[1]["count"];
      }
      if (this.countEval[2]["count"] > maxEval) {
        maxEval = this.countEval[2]["count"];
      }
      if (this.countEval[3]["count"] > maxEval) {
        maxEval = this.countEval[3]["count"];
      }
      if (this.countEval[4]["count"] > maxEval) {
        maxEval = this.countEval[4]["count"];
      }
      if (this.countEval[5]["count"] > maxEval) {
        maxEval = this.countEval[5]["count"];
      }

      this.countEval[1]["width"] = (this.countEval[1]["count"] * 100) / maxEval;
      this.countEval[2]["width"] = (this.countEval[2]["count"] * 100) / maxEval;
      this.countEval[3]["width"] = (this.countEval[3]["count"] * 100) / maxEval;
      this.countEval[4]["width"] = (this.countEval[4]["count"] * 100) / maxEval;
      this.countEval[5]["width"] = (this.countEval[5]["count"] * 100) / maxEval;

      if (this.countFeedback > 0) {
        this.evalFeedback = (this.evalFeedback / this.countFeedback).toFixed(2);
      }
      this.starsFeedback = this.getStars(this.evalFeedback);
    } catch (error) {
      console.log(error);
    }
    if (window.innerWidth < 1200) {
      this.slidesPerView = 4;
      this.spaceBetween = 15;
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".feedback-header");
        if (!basket) return false;
        let price = document.querySelector(".product-basket .price");
        let top = document.querySelector(".subscriber-section");
        top = top.offsetTop - window.innerHeight - 40;
        if (window.scrollY > top) {
          price.classList.remove("fixed");
        } else {
          price.classList.add("fixed");
        }
      });
    } else {
      window.addEventListener("scroll", function () {
        let basket = document.querySelector(".product-basket");
        if (!basket) return false;
        let menu = document.querySelector(".page-content");
        let top = document.querySelector(".subscriber-section");
        top = top.offsetTop - window.innerHeight / 2;
        if (window.scrollY > top) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        } else if (window.scrollY > menu.offsetTop - 35) {
          menu.classList.add("fixed");
          basket.classList.add("fixed");
        } else if (menu.classList.contains("fixed")) {
          menu.classList.remove("fixed");
          basket.classList.remove("fixed");
        }
      });
    }
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isOpenProfileName = false;
        that.isOpenNotOrder = false;
        that.isOpenExistFeedback = false;
        that.isOpenAddFeedback = false;
        for (let item in that.feedback) {
          that.feedback[item].isOpenPopupGallery = false;
        }
        that.isOpenPopup(false);
      }
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
      this.saveCartToUser();
      if (localStorage.getItem("product_favorite")) {
        this.clickFavoriteProduct(this.product);
        localStorage.removeItem("product_favorite");
      } else this.openAddFeedbackBlock();
    }
    this.getFavoriteProduct(this.product);
    this.getBasketProduct(this.product);
  },
  watch: {
    sortValue() {
      this.checkFilters();
    },
    checkedWithImages() {
      this.checkFilters();
    },
    checkedCountEval1() {
      this.checkFilters();
    },
    checkedCountEval2() {
      this.checkFilters();
    },
    checkedCountEval3() {
      this.checkFilters();
    },
    checkedCountEval4() {
      this.checkFilters();
    },
    checkedCountEval5() {
      this.checkFilters();
    },
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenProfileName(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".profile-name-popup button").innerHTML =
            "Сохранить";
        }, 100);
      }
    },
    isOpenNotOrder(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenExistFeedback(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenAddFeedback(isOpen) {
      this.isOpenPopup(isOpen);
      if (window.innerWidth < 800) {
        setTimeout(function () {
          document.querySelector(".add-feedback-popup button").innerHTML =
            "Отправить";
        }, 100);
      }
    },
  },
  methods: {
    checkFilters() {
      this.feedback = [];
      let isEval = 0;
      if (this.checkedCountEval1) isEval += 1;
      if (this.checkedCountEval2) isEval += 1;
      if (this.checkedCountEval3) isEval += 1;
      if (this.checkedCountEval4) isEval += 1;
      if (this.checkedCountEval5) isEval += 1;
      for (let item in this.allFeedback) {
        let is_active = false;
        item = this.allFeedback[item];
        if (isEval > 0 && this.checkedCountEval1 && item["eval"] === 1) {
          is_active = true;
        }
        if (isEval > 0 && this.checkedCountEval2 && item["eval"] === 2) {
          is_active = true;
        }
        if (isEval > 0 && this.checkedCountEval3 && item["eval"] === 3) {
          is_active = true;
        }
        if (isEval > 0 && this.checkedCountEval4 && item["eval"] === 4) {
          is_active = true;
        }
        if (isEval > 0 && this.checkedCountEval5 && item["eval"] === 5) {
          is_active = true;
        }
        if (isEval === 0) is_active = true;
        if (this.checkedWithImages && item["images"].length === 0) {
          is_active = false;
        }
        if (is_active) this.feedback.push(item);
      }

      let key = "count_likes";
      if (this.sortValue === Locale.TEXTS.feedbackFilterNew) key = "id";
      this.feedback = this.feedback.sort((item1, item2) =>
        item1[key] > item2[key] ? -1 : 1
      );
    },
  },
};
</script>
