import Const from "@/api/const";

const LOCALE_RU = {
  feedbackMore: "Читать полностью",
  subscriberText:
    "Закрытые распродажи, ответы на вопросы, обзоры новинок в нашем",
  subscriberUrl: "телеграмм-канале",
  subscriberButton: "Подписаться",
  catalogAllProducts: "Каталог все продукты",
  blogTitle: "Блог",
  backProfile: "В профиль",
  profileTitle: "Настройка профиля",
  month1: "Январь",
  month2: "Февраль",
  month3: "Март",
  month4: "Апрель",
  month5: "Май",
  month6: "Июнь",
  month7: "Июль",
  month8: "Август",
  month9: "Сентябрь",
  month10: "Откябрь",
  month11: "Ноябрь",
  month12: "Декабрь",
  monthd1: "января",
  monthd2: "февраля",
  monthd3: "марта",
  monthd4: "апреля",
  monthd5: "мая",
  monthd6: "июня",
  monthd7: "июля",
  monthd8: "августа",
  monthd9: "сентября",
  monthd10: "откября",
  monthd11: "ноября",
  monthd12: "декабря",
  rus: "Россия",
  kz: "Казахстан",
  bl: "Беларусь",
  uk: "Украина",
  mol: "Молдавия",
  tur: "Турция",
  bul: "Болгария",
  articleMore: "Показать ещё статьи",
  articleAll: "Все записи",
  backArticle: "Вернуться в блог",
  countViewsText: "Просмотры статьи",
  countLikesText: "Оценить статью",
  countCommentsText: "Написать комментарий",
  shareTelegram: "Поделиться в телеграмм",
  articleRecommended: "Вам может понравиться",
  catalogTitle: "Каталог товаров",
  hrefToBlog: "Перейти в блог",
  withSound: "Звук включен",
  withoutSound: "Без звука",
  questionsH1: "Вопросы и ответы по продукту",
  countQuestionsText: "Ответов <br />на вопросы ",
  authorText: "Автор",
  currencyText: "руб.",
  toBasketText: "В корзину",
  complexBasketText: "Купить в комплекте",
  yourBenefit: "Вы экономите",
  bonusBuy: " на следующую покупку",
  addBasketText: "Добавить в корзину",
  feedbackH1: "Отзывы о продукте",
  filterDisplay: "Показать",
  feedbackFilterNew: "новые",
  feedbackFilterUseful: "полезные",
  feedbackFilterValue: "Сначала",
  feedbackAnswerShow: "Показать ответ",
  feedbackAnswerHide: "Скрыть ответ",
  onlyWithImages: "Только с фото",
  feedbackEval: "Оценка",
  filterText: "Фильтры",
  feedbackNewText: "Сначала новые отзывы",
  feedbackUsefulText: "Сначала полезные отзывы",
  backCatalog: "Вернуться в каталог",
  combinedGoodsText: "Комплектации",
  description: "Описание",
  feedbacks: "Отзывы",
  questions: "Вопросы",
  composition: "Состав",
  maintenance: "Содержание активных компонентов в суточной дозировке",
  maintenancePercent: "% от АУП/РСП",
  showExplanation: "Развернуть пояснение",
  hideExplanation: "Свернуть пояснение",
  instruction: "Рекомендованный способ употребления",
  release_form: "Форма выпуска",
  documents: "Сертификаты",
  feedbackAll: "Все отзывы",
  feedbackDisplayAll: "Показать все отзывы",
  questionsAll: "Все вопросы",
  questionsDisplayAll: "Показать все вопросы",
  thing: "шт.",
  feedbackOne: "отзыв",
  feedbackTwo: "отзыва",
  feedbackMany: "отзывов",
  instructionMobile: "Прием",
  allText: "Все",
  lotText: "Еще..",
  sortPopular: "Популярные",
  sortNew: "Новинки",
  sortPrice: "По возрастанию цены",
  sortMinusPrice: "По убыванию цены",
  filterClear: "Сбросить",
  filterClearAll: "Сбросить все фильтры",
  lotDisplay: "Показать еще",
  close: "Закрыть",
  filterCategory: "Категории",
  filterComponent: "Компонент",
  filterAction: "Действие",
  filterForWhom: "Для кого",
  filterReleaseForm: "Форма выпуска",
  filterSubmit: "Применить фильтры",
  returnBack: "Вернуться назад",
  closeFilter: "Закрыть фильтры",
  displayProducts: "Показать товары",
  orderText: "Заказы",
  searchText: "Поиск",
  catalogText: "Каталог",
  productDisplayAll: "Показать все товары",
  popularGoods: "Популярные товары",
  searchHistory: "История поиска",
  popularQueries: "Популярные запросы",
  findText: "Найти",
  enterProductName: "Введите название продукта",
  forExample: "Например",
  orText: "или",
  searchResultNoneH3:
    "Пожалуйста, сформируйте запрос иначе, либо попробуйте найти необходимый продукт в <a href='/catalog'>каталоге</a>",
  searchResultNoneH2: "Вам может быть интересно",
  regionPopupH5: "Регион доставки",
  regionRus: "Россия и страны СНГ",
  regionRusDesc: "Валюта оплаты: RUB",
  regionUae: "Объединенные Арабские Эмираты (ОАЭ)",
  regionUaeMobile: "ОАЭ",
  regionUaeDesc: "Валюта оплаты: AED",
};

const LOCALE_EN = {
  feedbackMore: "Read all",
  subscriberText: "Subscribe to",
  subscriberUrl: "telegram channel",
  subscriberButton: "Subscribe",
  catalogAllProducts: "Catalog all products",
  blogTitle: "Blog",
  backProfile: "Back to profile",
  profileTitle: "Profile setup",
  month1: "January",
  month2: "February",
  month3: "March",
  month4: "April",
  month5: "May",
  month6: "June",
  month7: "July",
  month8: "August",
  month9: "September",
  month10: "October",
  month11: "November",
  month12: "December",
  monthd1: "january",
  monthd2: "february",
  monthd3: "march",
  monthd4: "april",
  monthd5: "may",
  monthd6: "june",
  monthd7: "july",
  monthd8: "august",
  monthd9: "september",
  monthd10: "october",
  monthd11: "november",
  monthd12: "december",
  rus: "Russia",
  kz: "Kazakhstan",
  bl: "Belarus",
  uk: "Ukraine",
  mol: "Moldova",
  tur: "Türkiye",
  bul: "Bulgaria",
  articleMore: "Show more articles",
  articleAll: "All articles",
  backArticle: "Back to blog",
  countViewsText: "Article views",
  countLikesText: "Rate article",
  countCommentsText: "Write a comment",
  shareTelegram: "Share on telegram",
  articleRecommended: "See also",
  catalogTitle: "Catalog",
  hrefToBlog: "Go to blog",
  withSound: "Sound on",
  withoutSound: "Soundless",
  questionsH1: "Questions and answers on the",
  countQuestionsText: "Answers <br />to questions ",
  authorText: "Author",
  currencyText: "AED",
  toBasketText: "Buy now",
  complexBasketText: "Buy complex",
  yourBenefit: "You save",
  bonusBuy: "bonus on next purchase",
  addBasketText: "Buy now",
  feedbackH1: "Product feedback",
  filterDisplay: "Show",
  feedbackFilterNew: "new",
  feedbackFilterUseful: "helpful",
  feedbackFilterValue: "Initially",
  feedbackAnswerShow: "Show answer",
  feedbackAnswerHide: "Hide answer",
  onlyWithImages: "Only with photo",
  feedbackEval: "Rating",
  filterText: "Filters",
  feedbackNewText: "New feedback first",
  feedbackUsefulText: "Helpful feedback first",
  backCatalog: "Back to catalog",
  combinedGoodsText: "Collection",
  description: "Description",
  feedbacks: "Feedback",
  questions: "Questions",
  composition: "Composition",
  maintenance: "Active ingredients per serving",
  maintenancePercent: "% DV",
  showExplanation: "Expand explanation",
  hideExplanation: "Collapse explanation",
  instruction: "Suggested use",
  release_form: "Release form",
  documents: "Documents",
  feedbackAll: "All feedback",
  feedbackDisplayAll: "Show all feedback",
  questionsAll: "All questions",
  questionsDisplayAll: "Show all questions",
  thing: "amount",
  feedbackOne: "feedback",
  feedbackTwo: "feedback",
  feedbackMany: "feedback",
  instructionMobile: "Medication",
  allText: "All",
  lotText: "More..",
  sortPopular: "Popular",
  sortNew: "New",
  sortPrice: "Cheap first",
  sortMinusPrice: "Expensive first",
  filterClear: "Reset",
  filterClearAll: "Reset all filters",
  lotDisplay: "Show more",
  close: "Close",
  filterCategory: "Categories",
  filterAction: "Action",
  filterComponent: "Component",
  filterForWhom: "For whom",
  filterReleaseForm: "Release form",
  filterSubmit: "Apply filters",
  returnBack: "Come back",
  closeFilter: "Close filters",
  displayProducts: "Show products",
  orderText: "Orders",
  searchText: "Search",
  catalogText: "Catalog",
  productDisplayAll: "Show all products",
  popularGoods: "Popular Goods",
  searchHistory: "Search history",
  popularQueries: "Popular queries",
  findText: "Find",
  enterProductName: "Enter product name",
  forExample: "For example",
  orText: "or",
  searchResultNoneH3:
    "Please formulate your request differently, or try to find the required product in <a href='catalog'>catalog</a>",
  searchResultNoneH2: "Might be interesting",
  regionPopupH5: "Delivery region",
  regionRus: "Russia and the CIS region",
  regionRusDesc: "Payment currency: RUB",
  regionUae: "United Arab Emirates (UAE)",
  regionUaeMobile: "UAE",
  regionUaeDesc: "Payment currency: AED",
};

let TEXTS = LOCALE_RU;
if (Const.LOCALE === "en") TEXTS = LOCALE_EN;

export default {
  LOCALE: Const.LOCALE,
  TEXTS: TEXTS,
};
