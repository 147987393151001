<template>
  <HeaderBlock />
  <section class="promotion-section" v-if="promotion">
    <div class="banner">
      <img :src="banner.image_object" />
    </div>
    <div v-if="products.length > 0" class="products-items">
      <ProductItemBlock
        v-for="item in products"
        :product="item.product"
        :key="item"
        :isFavorite="true"
        :isBasket="true"
        :type="'catalog'"
        :sourcePage="9"
      />
    </div>
    <div
      v-if="isDisplayLot"
      @click="displayProducts(pageNumber + 1)"
      class="lot-loading-products"
    >
      <span>{{ locale.TEXTS.lotDisplay }}</span>
    </div>
    <h1>
      {{ promotion.headline_h1 }}
      <span v-if="promotion.status > 2"> - Акция завершена</span>
    </h1>
    <p v-if="promotion.description_full">{{ promotion.description_full }}</p>
  </section>
  <FooterBlock />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    ProductItemBlock,
  },
  data() {
    return {
      promotion: null,
      banner: {},
      slug: "",
      authToken: String,
      profile: Object,
      products: [],
      isDisplayLot: false,
      pageNumber: 1,
      locale: Locale,
    };
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_promotion;
      const result = await api.shop.getPromotionFromPath(this.slug);
      let promotion = result["data"]["results"][0];
      if (promotion) this.promotion = promotion;
      else window.location.href = "/";
      setTimeout(function () {
        let top = 160;
        if (window.innerWidth < 1200) top = 50;
        if (document.querySelector(".this-test-site")) top += 50;
        window.scrollTo({
          top: top,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
      document.title = promotion["title"];
      document.getElementsByTagName("meta")["description"].content =
        promotion["meta_description"];
      if (window.innerWidth < 450) {
        this.banner = this.promotion.banner_mobile;
      } else this.banner = this.promotion.banner_desktop;
      this.displayProducts(1);
    } catch (error) {
      window.location.href = "/";
    }
  },
  methods: {
    async displayProducts(page) {
      if (page === 1) this.products = [];
      try {
        this.pageNumber = page;
        let payload = {
          id: this.promotion.id,
          count: 9,
          page_size: page,
        };
        const result = await api.shop.getPromotionProducts(payload);
        for (let item in result["data"]["results"]) {
          this.products.push(result["data"]["results"][item]);
        }
        if (result["data"]["count"] > this.products.length) {
          this.isDisplayLot = true;
        } else this.isDisplayLot = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
