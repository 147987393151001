<template>
  <div class="add-delivery-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isAddDelivery = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div class="select-tab">
        <div
          :class="{ active: selectDeliveryType === 1 }"
          @click="selectDeliveryType = 1"
        >
          <span>Пункт выдачи</span>
          <span>Бесплатно</span>
        </div>
        <div
          :class="{ active: selectDeliveryType === 2 }"
          @click="selectDeliveryType = 2"
        >
          <span>Курьером</span>
          <span>199 руб.</span>
        </div>
      </div>
      <div v-if="selectDeliveryType === 1" class="delivery-points">
        <div id="map"></div>
        <div class="selected-point" v-if="selectedPoint">
          <span class="name" v-if="selectedPoint.is_postamat">
            Почтомат {{ selectedPoint.delivery_service_name }}
          </span>
          <span class="name" v-else>
            Пункт выдачи {{ selectedPoint.delivery_service_name }}
          </span>
          <img
            src="@/assets/images/shop-close.svg"
            @click="selectedPoint = null"
          />
          <span class="address">{{ selectedPoint.address }}</span>
          <span class="working">
            <span>Режим работы:</span>
            <span v-html="selectedPoint.working_hours"></span>
          </span>
          <button @click.stop="createDeliveryPoint">Выбрать</button>
          <span class="comment" v-if="selectedPoint.comment">
            {{ selectedPoint.comment }}
          </span>
        </div>
      </div>
      <div v-if="selectDeliveryType === 2" class="delivery-courier">
        <div class="group-input" :class="{ error: isDeliveryCourierCity }">
          <small>Укажите город*</small>
          <input
            type="text"
            placeholder="Москва"
            @focus="isSearchCities = true"
            v-model="deliveryCourierCity"
          />
        </div>
        <div v-if="isSearchCities" class="search-cities">
          <span
            v-for="item in citiesDisplay"
            :key="item"
            @click="changeCity(item.name)"
          >
            {{ item.name }} <span>{{ item.region }}</span>
          </span>
        </div>
        <div v-else-if="notDeliveries" class="not-deliveries">
          <span>
            В указанный город нет доставки. Укажите другой город или
            <a href="/partner-points" target="_blank">
              оформите заказ у партнеров
            </a>
          </span>
        </div>
        <div class="group-input" :class="{ error: isDeliveryCourierStreet }">
          <small>Улица *</small>
          <input
            type="text"
            required="required"
            placeholder="Укажите улицу"
            v-model="deliveryCourierStreet"
          />
        </div>
        <div class="group-input" :class="{ error: isDeliveryCourierHouse }">
          <small>Дом *</small>
          <input
            type="text"
            required="required"
            placeholder="Номер дома"
            v-model="deliveryCourierHouse"
          />
        </div>
        <div class="group-input" :class="{ error: isDeliveryCourierFlat }">
          <small>Квартира / Комната</small>
          <input
            type="text"
            placeholder="Если актуально"
            v-model="deliveryCourierFlat"
          />
        </div>
        <div class="group-input" :class="{ error: isDeliveryCourierComment }">
          <small>Комментарий курьеру</small>
          <textarea
            placeholder="Подробности, как попасть до адреса"
            v-model="deliveryCourierComment"
          ></textarea>
        </div>
        <button @click.stop="createDeliveryCourier">Далее</button>
      </div>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import api from "@/api";

let myMap;

export default {
  mixins: [mainMixin],
  props: {
    deliveriesPoints: String,
    isCreate: Boolean,
    isSettings: Boolean,
    isCheckout: Boolean,
  },
  data() {
    return {
      selectDeliveryType: 1,
      selectedPoint: null,
      isSearchCities: false,
      citiesDefault: [
        { name: "Москва", region: "" },
        { name: "Санкт-Петербург", region: "" },
        { name: "Екатеринбург", region: ", Свердловская область" },
        { name: "Краснодар", region: ", Краснодарский край" },
        { name: "Нижний новгород", region: ", Нижегородская область" },
        { name: "Новосибирск", region: ", Новосибирская область" },
        { name: "Ростов-на-Дону", region: ", Ростовкая область" },
        { name: "Самара", region: ", Самарская область" },
        { name: "Уфа", region: ", Республика Башкортостан" },
        { name: "Челябинск", region: ", Челябинская область" },
      ],
      cities: [],
      citiesDisplay: [],
      notDeliveries: false,
      deliveryCourierCity: "",
      deliveryCourierStreet: "",
      deliveryCourierHouse: "",
      deliveryCourierFlat: "",
      deliveryCourierComment: "",
      isDeliveryCourierCity: false,
      isDeliveryCourierStreet: false,
      isDeliveryCourierHouse: false,
      isDeliveryCourierFlat: false,
      isDeliveryCourierComment: false,
      isInitYMaps: false,
    };
  },
  async created() {
    if (this.$parent.editDelivery) {
      this.clickEditDelivery(this.$parent.editDelivery);
    } else this.clickAddDelivery();
    this.citiesDisplay = this.citiesDefault;
    this.getCities(1);
  },
  methods: {
    async getCities(page) {
      try {
        const result = await api.shop.getCities(page);
        for (let item in result["data"]["results"]) {
          this.cities.push(result["data"]["results"][item]);
        }
        if (result["data"]["next"]) this.getCities(page + 1);
      } catch (error) {
        console.log(error);
      }
    },
    async changeCity(newCity) {
      this.isSearchCities = false;
      this.deliveryCourierCity = newCity;
      try {
        const result = await api.shop.getDeliveryFromCity(newCity);
        if (result["data"]["couriers"].length > 0) {
          this.notDeliveries = false;
        } else this.notDeliveries = true;
      } catch (error) {
        console.log(error);
      }
    },
    async createDeliveryCourier() {
      let error = false;
      if (this.deliveryCourierCity === "") {
        this.isDeliveryCourierCity = true;
        error = true;
      } else if (this.notDeliveries || this.isSearchCities) {
        this.isDeliveryCourierCity = true;
        error = true;
      } else this.isDeliveryCourierCity = false;
      if (this.deliveryCourierStreet === "") {
        this.isDeliveryCourierStreet = true;
        error = true;
      } else this.isDeliveryCourierStreet = false;
      if (this.deliveryCourierHouse === "") {
        this.isDeliveryCourierHouse = true;
        error = true;
      } else this.isDeliveryCourierHouse = false;
      if (error) return false;
      let address_raw = this.deliveryCourierCity;
      address_raw += ", " + this.deliveryCourierStreet;
      address_raw += ", " + this.deliveryCourierHouse;
      try {
        let data = {
          type: 2,
          address_raw: address_raw,
          city: this.deliveryCourierCity,
          street: this.deliveryCourierStreet,
          house: this.deliveryCourierHouse,
        };
        if (!this.isCheckout && this.isCreate) data.is_default = true;
        if (this.deliveryCourierFlat) {
          data["flat"] = this.deliveryCourierFlat;
          data["address_raw"] += " - " + data["flat"];
        }
        if (this.deliveryCourierComment) {
          data["comment"] = this.deliveryCourierComment;
        }
        let create_item_id = new Date().getTime();
        if (this.isCreate) {
          if (this.authToken) {
            const result = await api.profile.postUserDelivery(data);
            create_item_id = result["data"]["id"];
            if (this.isSettings) {
              let item = {
                id: create_item_id,
                type: 2,
                city: data["city"],
                address_raw: data["address_raw"],
                pointId: data["point_id"],
                info_point: {},
                cost: 199,
                courier: {
                  city: data["city"],
                  street: data["street"],
                  house: data["house"],
                  flat: data["flat"],
                  comment: data["comment"],
                },
              };
              this.$parent.deliveries.push(item);
            }
          } else {
            localStorage.setItem("createDeliveryId", create_item_id);
            localStorage.setItem("createDeliveryType", 2);
            localStorage.setItem("createDeliveryCity", data["city"]);
            localStorage.setItem("createDeliveryStreet", data["street"]);
            localStorage.setItem("createDeliveryHouse", data["house"]);
            localStorage.setItem("createDeliveryFlat", data["flat"]);
            localStorage.setItem("createDeliveryComment", data["comment"]);
            localStorage.setItem(
              "createDeliveryAddressRaw",
              data["address_raw"]
            );
          }
        }
        if (this.$parent.editDelivery) {
          await api.profile.putUserDelivery(
            this.$parent.editDelivery["id"],
            data
          );
          this.$parent.editDelivery["type"] = 2;
          this.$parent.editDelivery["address_raw"] = data["address_raw"];
          this.$parent.editDelivery["city"] = data["city"];
          this.$parent.editDelivery["info_point"] = {};
        } else if (this.isCheckout) {
          let item = {
            id: create_item_id,
            type: 2,
            city: data["city"],
            address_raw: data["address_raw"],
            pointId: data["point_id"],
            info_point: {},
            cost: 199,
            courier: {
              city: data["city"],
              street: data["street"],
              house: data["house"],
              flat: data["flat"],
              comment: data["comment"],
            },
          };
          this.$parent.deliveries.push(item);
          this.$parent.selectDelivery = item;
          this.$parent.selectDeliveryId = item["id"];
        }
        if (!this.isCheckout && this.isCreate) {
          localStorage.setItem("city", data["city"]);
          this.$emit("changeCity", data["city"]);
          this.$emit("tooltipDelivery", true);
        }
        this.$parent.isAddDelivery = false;
      } catch (error) {
        console.log(error);
      }
    },
    async createDeliveryPoint() {
      try {
        let data = {
          type: 1,
          point_id: this.selectedPoint.id,
        };
        if (!this.isCheckout && this.isCreate) data.is_default = true;
        let create_item_id = new Date().getTime();
        if (this.isCreate) {
          if (this.authToken) {
            const result = await api.profile.postUserDelivery(data);
            create_item_id = result["data"]["id"];
            if (this.isSettings) {
              let item = {
                id: create_item_id,
                type: 1,
                city: null,
                address_raw: null,
                pointId: data["point_id"],
                info_point: this.selectedPoint,
                cost: this.selectedPoint["cost_of_delivery"],
              };
              this.$parent.deliveries.push(item);
            }
          } else {
            localStorage.setItem("createDeliveryId", create_item_id);
            localStorage.setItem("createDeliveryType", 1);
            localStorage.setItem("createDeliveryPointId", data["point_id"]);
          }
        }
        if (this.$parent.editDelivery) {
          await api.profile.putUserDelivery(
            this.$parent.editDelivery["id"],
            data
          );
          this.$parent.editDelivery["type"] = 1;
          this.$parent.editDelivery["address_raw"] = null;
          this.$parent.editDelivery["info_point"] = this.selectedPoint;
        } else if (this.isCheckout) {
          let item = {
            id: create_item_id,
            type: 1,
            city: null,
            address_raw: null,
            pointId: data["point_id"],
            info_point: this.selectedPoint,
            cost: this.selectedPoint["cost_of_delivery"],
          };
          this.$parent.deliveries.push(item);
          this.$parent.selectedPoint = null;
          this.$parent.selectDelivery = item;
          this.$parent.selectDeliveryId = item["id"];
        }
        if (!this.isCheckout && this.isCreate) {
          localStorage.setItem("city", this.selectedPoint["address_city"]);
          this.$emit("changeCity", this.selectedPoint["address_city"]);
          this.$emit("tooltipDelivery", true);
        }
        this.$parent.isAddDelivery = false;
      } catch (error) {
        console.log(error);
      }
    },
    initYMaps(lat, lon) {
      this.isInitYMaps = false;
      let block = document.querySelector("#map ymaps");
      if (block) block.remove();
      myMap = new window.ymaps.Map(
        "map",
        {
          center: [lat, lon],
          zoom: 14,
          controls: ["searchControl", "zoomControl", "geolocationControl"],
        },
        {
          searchControlProvider: "yandex#search",
        }
      );
      this.pointsYMaps();
    },
    pointsYMaps() {
      let points = this.deliveriesPoints.split(",");
      if (points.length === 0) return false;
      if (this.isInitYMaps) return false;
      this.isInitYMaps = true;
      let clusterer = new window.ymaps.Clusterer({
        preset: "islands#invertedVioletClusterIcons",
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
      });
      clusterer.events.add(["mouseenter", "mouseleave"], function (e) {
        let target = e.get("target");
        let type = e.get("type");
        if (typeof target.getGeoObjects !== "undefined") {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#invertedPinkClusterIcons");
          } else {
            target.options.set("preset", "islands#invertedVioletClusterIcons");
          }
        } else {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#pinkIcon");
          } else {
            target.options.set("preset", "islands#violetIcon");
          }
        }
      });
      let geoObjects = [];
      let that = this;
      myMap.events.add("mousedown", function () {
        that.selectedPoint = null;
      });
      let num = 0;
      for (let item in points) {
        item = points[item].split("-");
        if (item[3] === "p") continue;
        geoObjects[num] = new window.ymaps.Placemark(
          [Number(item[1]), Number(item[2])],
          {},
          {
            preset: "islands#violetIcon",
          }
        );
        geoObjects[num].events.add(["click"], function () {
          that.getInfoPoint(item[0]);
        });
        num += 1;
      }
      clusterer.add(geoObjects);
      myMap.geoObjects.add(clusterer);

      clusterer = new window.ymaps.Clusterer({
        preset: "islands#invertedDarkBlueClusterIcons",
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
      });
      clusterer.events.add(["mouseenter", "mouseleave"], function (e) {
        let target = e.get("target");
        let type = e.get("type");
        if (typeof target.getGeoObjects !== "undefined") {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#invertedNightClusterIcons");
          } else {
            target.options.set(
              "preset",
              "islands#invertedDarkBlueClusterIcons"
            );
          }
        } else {
          if (type === "mouseenter") {
            target.options.set("preset", "islands#nightIcon");
          } else {
            target.options.set("preset", "islands#darkBlueIcon");
          }
        }
      });
      geoObjects = [];
      myMap.events.add("mousedown", function () {
        that.selectedPoint = null;
      });
      num = 0;
      for (let item in points) {
        item = points[item].split("-");
        if (item[3] === "b") continue;
        geoObjects[num] = new window.ymaps.Placemark(
          [Number(item[1]), Number(item[2])],
          {},
          {
            preset: "islands#darkBlueIcon",
          }
        );
        geoObjects[num].events.add(["click"], function () {
          that.getInfoPoint(item[0]);
        });
        num += 1;
      }
      clusterer.add(geoObjects);
      myMap.geoObjects.add(clusterer);
    },
    async getInfoPoint(point_id) {
      try {
        const result = await api.shop.getInfoDeliveryPoint(point_id);
        this.selectedPoint = result["data"]["results"][0];
      } catch (error) {
        console.log(error);
      }
    },
    clickEditDelivery(item) {
      this.selectDeliveryType = item["type"];
      if (item["type"] === 1) {
        this.deliveryCourierStreet = "";
        this.deliveryCourierHouse = "";
        this.deliveryCourierFlat = "";
        this.deliveryCourierComment = "";
        this.selectYMaps();
      } else {
        this.deliveryCourierCity = item["city"];
        this.deliveryCourierStreet = item["street"];
        this.deliveryCourierHouse = item["house"];
        this.deliveryCourierFlat = item["flat"];
        this.deliveryCourierComment = item["comment"];
      }
    },
    clickAddDelivery() {
      this.deliveryCourierStreet = "";
      this.deliveryCourierHouse = "";
      this.deliveryCourierFlat = "";
      this.deliveryCourierComment = "";
      this.selectDeliveryType = 1;
      this.selectYMaps();
    },
    selectYMaps() {
      let that = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let lat = position.coords.latitude;
          let lon = position.coords.longitude;
          setTimeout(function () {
            try {
              window.ymaps.ready(that.initYMaps(lat, lon));
            } catch (error) {
              console.log(error);
            }
          }, 200);
        },
        function (error) {
          console.log(error);
          setTimeout(function () {
            window.ymaps.ready(that.initYMaps(55.751574, 37.573856));
          }, 200);
        }
      );
    },
  },
  watch: {
    selectDeliveryType(value) {
      if (value === 1) {
        this.selectYMaps();
      }
    },
    deliveryCourierCity(value) {
      if (value === "") {
        this.citiesDisplay = this.citiesDefault;
        return false;
      }
      this.citiesDisplay = [];
      value = value.toLowerCase();
      for (let item in this.cities) {
        let city = this.cities[item]["city"].toLowerCase();
        if (city.indexOf(value) !== -1) {
          let region = this.cities[item]["state"];
          if (region) region = ", " + region;
          else region = "";
          this.citiesDisplay.push({
            name: this.cities[item]["city"],
            region: region,
          });
        }
      }
      if (this.citiesDisplay.length === 1) {
        if (value === this.citiesDisplay[0].name.toLowerCase()) {
          this.changeCity(this.citiesDisplay[0].name);
        } else {
          this.isSearchCities = true;
          this.notDeliveries = false;
        }
      } else if (this.citiesDisplay.length === 0) {
        this.isSearchCities = false;
        this.notDeliveries = true;
      } else {
        this.isSearchCities = true;
        this.notDeliveries = false;
      }
      this.citiesDisplay = this.citiesDisplay.slice(0, 10);
    },
  },
};
</script>
