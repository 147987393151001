<template>
  <HeaderBlock />
  <section class="catalog-section page-section">
    <h1>{{ blogCategory.name }}</h1>
    <div class="categories">
      <a class="link" :class="{ active: blogCategory.id == null }" href="/blog">
        {{ locale.TEXTS.articleAll }} <span>{{ countAll }}</span>
      </a>
      <a
        v-for="item in categories"
        :key="item"
        class="link"
        :class="{ active: blogCategory.id === item.id }"
        :href="'/blog/' + item.slug"
      >
        {{ item.name }}<span>{{ item.count }}</span>
      </a>
    </div>
    <div class="articles-items">
      <div class="article-item" v-for="item in articles" :key="item">
        <ArticleItemBlock :item="item" />
      </div>
    </div>
    <div
      v-if="allArticles.length > articles.length"
      @click="setDisplayArticles"
      class="lot-loading-articles"
    >
      <span>{{ locale.TEXTS.articleMore }}</span>
    </div>
  </section>
  <FooterBlock />
</template>

<script>
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ArticleItemBlock from "@/components/ArticleItemBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";

export default {
  components: {
    HeaderBlock,
    FooterBlock,
    ArticleItemBlock,
  },
  props: {
    blogCategory: Object,
    slug: String,
    authToken: String,
    profile: Object,
  },
  data() {
    return {
      countAll: 0,
      allArticles: [],
      articles: [],
      categories: {},
      locale: Locale,
    };
  },
  async created() {
    try {
      const result = await api.shop.getBlogArticles();
      for (let item in result["data"]["results"]) {
        item = result["data"]["results"][item];
        let is_display = false;
        for (let category in item["categories"]) {
          category = item["categories"][category];
          if (!this.categories[category["id"]]) {
            let slug = "";
            if (category["shop_page"].length > 0) {
              slug = category["shop_page"][0]["url"];
            }
            this.categories[category["id"]] = {
              id: category["id"],
              name: category["name"],
              count: 0,
              slug: slug,
            };
          }
          this.categories[category["id"]]["count"] += 1;
          if (this.blogCategory.id === category["id"]) {
            is_display = true;
          }
        }
        if (is_display || !this.blogCategory.id) {
          this.allArticles.push(item);
        }
        this.countAll += 1;
      }
      this.articles = this.allArticles.slice(0, 8);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    setDisplayArticles() {
      this.articles = this.allArticles.slice(0, this.articles.length + 8);
    },
  },
};
</script>
