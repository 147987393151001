<template>
  <div class="search-block" :class="{ active: searchValue !== '' }" @click.stop>
    <div class="catalog-button unselectable" @click="openCatalog">
      <img src="@/assets/images/catalog.svg" height="28" />
      <span>{{ locale.TEXTS.catalogText }}</span>
    </div>
    <input
      type="text"
      v-model="searchValue"
      ref="search_value"
      id="search_value"
      @keyup.enter="goSearch(null)"
      @focus="focusSearch"
      :placeholder="locale.TEXTS.enterProductName"
    />
    <span v-if="searchValue === ''" @click.stop="openSearch">
      {{ locale.TEXTS.enterProductName }}. {{ locale.TEXTS.forExample }}:
      <a @click.stop="goSearch('Витамин С')">Витамин С</a>
      {{ locale.TEXTS.orText }}
      <a @click.stop="goSearch('Омега-3')">Омега-3</a>
    </span>
    <img class="search" src="@/assets/images/search.svg" height="28" />
    <img
      class="clear"
      @click="clearSearch"
      src="@/assets/images/shop-close.svg"
    />
    <button @click="goSearch(null)">{{ locale.TEXTS.findText }}</button>
    <div class="results-block">
      <div v-if="searchValue !== ''">
        <a
          v-for="prompt in promptQueries"
          :key="prompt"
          class="prompt-query-item"
          :class="{ hover: prompt.isHover }"
          :href="'/search?query=' + prompt.query"
          v-html="prompt.value"
        ></a>
        <div
          v-if="promptProducts.length > 0"
          class="popular-products prompt-products"
        >
          <div :class="'count-' + promptProducts.length">
            <a
              v-for="item in promptProducts"
              :key="item"
              class="popular-product-item"
              :class="{ hover: item.isHover }"
              :href="item.url"
            >
              <SearchProductBlock :product="item" />
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <h6 v-if="storyQueries.length > 0">{{ locale.TEXTS.searchHistory }}</h6>
        <h6 v-else>{{ locale.TEXTS.popularQueries }}</h6>
        <a
          v-for="item in storyQueries"
          :key="item"
          class="story-query-item"
          :class="{ hover: item.isHover }"
          @click.stop="goSearch(item.value)"
        >
          {{ item.value }}
          <img
            src="@/assets/images/shop-close.svg"
            @click.stop="deleteQuery(item.value)"
          />
        </a>
        <a
          v-for="item in popularQueries"
          :key="item"
          class="popular-query-item"
          :class="{ hover: item.isHover }"
          :href="'/search?query=' + item.value"
        >
          {{ item.value }}
        </a>
        <h6 class="products-h6">{{ locale.TEXTS.popularGoods }}</h6>
        <div class="popular-products">
          <div>
            <a
              v-for="item in popularProducts"
              :key="item"
              class="popular-product-item"
              :class="{ hover: item.isHover }"
              :href="'/catalog/' + item.shop_page[0].url"
            >
              <SearchProductBlock :product="item" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="catalog-popup" v-if="isOpenCatalog">
    <div class="wrap">
      <div class="close unselectable" @click="closeCatalog">
        <img src="@/assets/images/shop-close.svg" />
        <span>{{ locale.TEXTS.catalogText }}</span>
      </div>
      <a href="/catalog">{{ locale.TEXTS.productDisplayAll }}</a>
      <div class="columns">
        <div class="col">
          <h6>{{ locale.TEXTS.filterCategory }}</h6>
          <span v-for="item in catalogCategories" :key="item">
            <a :href="'/catalog/' + item.slug">{{ item.name }}</a>
          </span>
        </div>
        <div class="col">
          <h6>{{ locale.TEXTS.filterAction }}</h6>
          <span v-for="item in catalogActions" :key="item">
            <a :href="'/catalog/?sort=popular&actions=' + item.id">
              {{ item.name }}
            </a>
          </span>
          <!--          <h6>{{ locale.TEXTS.filterComponent }}</h6>-->
          <!--          <span v-for="item in catalogComponents" :key="item">-->
          <!--            <a :href="'/catalog/?sort=popular&components=' + item.id">-->
          <!--              {{ item.name }}-->
          <!--            </a>-->
          <!--          </span>-->
        </div>
        <div class="col">
          <h6>{{ locale.TEXTS.filterForWhom }}</h6>
          <span v-for="item in catalogForWhom" :key="item">
            <a :href="'/catalog/?sort=popular&forWhom=' + item.id">
              {{ item.name }}
            </a>
          </span>
        </div>
        <div class="col">
          <h6>{{ locale.TEXTS.filterReleaseForm }}</h6>
          <span v-for="item in catalogReleasesForm" :key="item">
            <a :href="'/catalog/?sort=popular&releasesForm=' + item.id">
              {{ item.name }}
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <nav :class="{ en: locale.LOCALE === 'en' }">
    <router-link to="/catalog">
      <img src="@/assets/images/catalog.svg" />
      <span>{{ locale.TEXTS.catalogText }}</span>
    </router-link>
    <a @click.stop="openSearch">
      <img src="@/assets/images/search.svg" />
      <span>{{ locale.TEXTS.searchText }}</span>
    </a>
    <router-link to="/cart" v-if="locale.LOCALE === 'ru'" class="basket">
      <img src="@/assets/images/shop-basket.svg" />
      <span>Корзина</span>
      <span class="count" v-if="countBasket">{{ countBasket }}</span>
    </router-link>
    <a href="/personal/orders">
      <img src="@/assets/images/orders.svg" />
      <span>{{ locale.TEXTS.orderText }}</span>
    </a>
    <a href="/personal" v-if="locale.LOCALE === 'ru'">
      <img src="@/assets/images/personal.svg" />
      <span>Профиль</span>
    </a>
  </nav>
</template>
<script>
import pageMixin from "@/mixins/pageMixin";
import SearchProductBlock from "@/components/SearchProductBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";
import Const from "@/api/const";

export default {
  mixins: [pageMixin],
  components: {
    SearchProductBlock,
  },
  props: {
    countBasket: Number,
  },
  data() {
    return {
      locale: Locale,
      userHash: "",
      searchValue: "",
      promptQueries: [],
      promptProducts: [],
      isOpenCatalog: false,
      catalogCategories: {},
      catalogComponents: {},
      catalogActions: {},
      catalogForWhom: {},
      catalogReleasesForm: {},
      popularQueries: [],
      storyQueries: [],
      popularProducts: [],
      selectPromptIndex: -1,
      promptRequestNumber: 0,
    };
  },
  async created() {
    window.addEventListener("click", () => {
      if (document.body.classList.contains("search-body")) {
        document.body.style.overflow = "auto";
        document.body.classList.remove("search-body");
      }
    });
    let that = this;
    window.addEventListener("keyup", (e) => {
      if (document.body.classList.contains("search-body")) {
        let maxIndex = that.popularQueries.length + that.popularProducts.length;
        let checkType = "popular";
        if (that.searchValue !== "") {
          maxIndex = that.promptQueries.length + that.promptProducts.length;
          checkType = "prompt";
        } else if (that.storyQueries.length > 0) {
          maxIndex = that.storyQueries.length + that.popularProducts.length;
          checkType = "story";
        }
        if (e.keyCode === 40) {
          that.selectPromptIndex += 1;
          if (that.selectPromptIndex >= maxIndex) {
            that.selectPromptIndex = 0;
          }
          if (checkType === "prompt") that.checkSelectPrompt();
          if (checkType === "story") that.checkSelectStory();
          if (checkType === "popular") that.checkSelectPopular();
        } else if (e.keyCode === 38) {
          that.selectPromptIndex -= 1;
          if (that.selectPromptIndex < 0) {
            that.selectPromptIndex = maxIndex - 1;
          }
          if (checkType === "prompt") that.checkSelectPrompt();
          if (checkType === "story") that.checkSelectStory();
          if (checkType === "popular") that.checkSelectPopular();
        } else if (e.keyCode === 13 && that.selectPromptIndex > -1) {
          that.enterSelectPrompt();
        }
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("query")) {
      this.searchValue = urlParams.get("query");
    }
    try {
      let result = await api.shop.getParamsCatalog();
      result = result["data"];
      for (let item in result["categories"]) {
        item = result["categories"][item];
        let slug = "";
        if (item["shop_page"].length > 0) {
          slug = item["shop_page"][0]["url"];
        }
        this.catalogCategories[item["id"]] = {
          id: item["id"],
          name: item["name"],
          slug: slug,
        };
      }
      for (let item in result["actions"]) {
        item = result["actions"][item];
        this.catalogActions[item["id"]] = {
          id: item["id"],
          name: item["name"],
        };
      }
      for (let item in result["components"]) {
        item = result["components"][item];
        this.catalogComponents[item["id"]] = {
          id: item["id"],
          name: item["name"],
        };
      }
      for (let item in result["for_whom"]) {
        item = result["for_whom"][item];
        this.catalogForWhom[item["id"]] = {
          id: item["id"],
          name: item["name"],
        };
      }
      for (let item in result["releases_form"]) {
        item = result["releases_form"][item];
        this.catalogReleasesForm[item["id"]] = {
          id: item["id"],
          name: item["name"],
        };
      }
    } catch (error) {
      console.log(error);
    }
    this.userHash = localStorage.getItem("userHash");
    if (!this.userHash) this.userHash = "";
    this.getPopularQueries();
    try {
      const result = await api.shop.getShopSearchPopularProducts(4);
      this.popularProducts = result["data"]["results"];
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    clearSearch() {
      this.searchValue = "";
      this.$nextTick(() => this.$refs["search_value"].focus());
    },
    openSearch() {
      if (window.innerWidth < 1200) {
        document.body.style.overflow = "hidden";
      }
      document.body.classList.add("search-body");
      this.$nextTick(() => this.$refs["search_value"].focus());
    },
    focusSearch() {
      setTimeout(function () {
        if (window.innerWidth < 1200) {
          document.body.style.overflow = "hidden";
        }
        document.body.classList.add("search-body");
      }, 250);
    },
    goSearch(value) {
      if (value) {
        window.location.href = "/search?query=" + value;
        return false;
      }
      if (this.searchValue === "") return false;
      window.location.href = "/search?query=" + this.searchValue;
    },
    async deleteQuery(query) {
      let payload = {
        query: query,
        user_hash: this.userHash,
      };
      try {
        await api.shop.deleteShopSearchStoryQuery(payload);
        this.getPopularQueries();
        let that = this;
        setTimeout(function () {
          that.openSearch();
        }, 100);
      } catch (error) {
        console.log(error);
      }
    },
    openCatalog() {
      document.body.style.overflow = "hidden";
      this.isOpenCatalog = true;
      let top = 103;
      if (document.querySelector(".this-test-site")) top += 50;
      top = top - window.pageYOffset;
      setTimeout(function () {
        document.querySelector(".catalog-popup .wrap").style.margin =
          top + "px auto 70px";
      }, 1);
    },
    closeCatalog() {
      document.body.style.overflow = "auto";
      this.isOpenCatalog = false;
    },
    async searchPrompt(query) {
      if (this.searchValue.length < 1) return false;
      if (this.searchValue !== query) return false;
      try {
        let queries = "";
        const result = await api.shop.getShopSearchPromptQuery(query);
        this.promptQueries = [];
        this.promptProducts = [];
        for (let item in result["data"]["queries"]) {
          item = result["data"]["queries"][item];
          query = query.toLowerCase();
          let value = query;
          if (item.indexOf(query) === -1) value = this.replace_letter(value);
          value = item.replace(value, "<span>" + value + "</span>");
          this.promptQueries.push({
            query: item,
            value: value,
          });
          if (queries !== "") queries += ",";
          queries += item;
        }
        if (queries !== "") {
          this.promptRequestNumber += 1;
          this.searchPromptProduct(queries, this.promptRequestNumber);
        } else {
          this.promptQueries = [
            {
              query: query,
              value: query,
            },
          ];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async searchPromptProduct(queries, number) {
      try {
        const result = await api.shop.getShopSearchPromptProduct(queries);
        if (number !== this.promptRequestNumber) return false;
        this.promptProducts = [];
        for (let product in result["data"]["products"]) {
          product = result["data"]["products"][product];
          product.url = "/search?query=" + this.promptQueries[0].query;
          product.url += "&product=" + product["shop_page"][0]["url"];
          for (let image in product["images"]) {
            product["images"][image]["image"]["image_object_middle"] =
              Const.API_URL +
              product["images"][image]["image"]["image_object_middle"];
          }
          this.promptProducts.push(product);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPopularQueries() {
      this.popularQueries = [];
      this.storyQueries = [];
      try {
        const result = await api.shop.getShopSearchPopularQueries(
          this.userHash
        );
        for (let item in result["data"]["populars"]) {
          this.popularQueries.push({
            value: result["data"]["populars"][item],
          });
        }
        for (let item in result["data"]["stories"]) {
          this.storyQueries.push({
            value: result["data"]["stories"][item],
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkSelectPrompt() {
      let isCheck = false;
      for (let item in this.promptQueries) {
        this.promptQueries[item].isHover = false;
        if (Number(this.selectPromptIndex) === Number(item)) {
          this.promptQueries[item].isHover = true;
          isCheck = true;
        }
      }
      let index = Number(this.selectPromptIndex) - this.promptQueries.length;
      for (let item in this.promptProducts) {
        this.promptProducts[item].isHover = false;
        if (Number(index) === Number(item) && !isCheck) {
          this.promptProducts[item].isHover = true;
        }
      }
    },
    checkSelectStory() {
      let isCheck = false;
      for (let item in this.storyQueries) {
        this.storyQueries[item].isHover = false;
        if (Number(this.selectPromptIndex) === Number(item)) {
          this.storyQueries[item].isHover = true;
          isCheck = true;
        }
      }
      let index = Number(this.selectPromptIndex) - this.storyQueries.length;
      for (let item in this.popularProducts) {
        this.popularProducts[item].isHover = false;
        if (Number(index) === Number(item) && !isCheck) {
          this.popularProducts[item].isHover = true;
        }
      }
    },
    checkSelectPopular() {
      let isCheck = false;
      for (let item in this.popularQueries) {
        this.popularQueries[item].isHover = false;
        if (Number(this.selectPromptIndex) === Number(item)) {
          this.popularQueries[item].isHover = true;
          isCheck = true;
        }
      }
      let index = Number(this.selectPromptIndex) - this.popularQueries.length;
      for (let item in this.popularProducts) {
        this.popularProducts[item].isHover = false;
        if (Number(index) === Number(item) && !isCheck) {
          this.popularProducts[item].isHover = true;
        }
      }
    },
    enterSelectPrompt() {
      for (let item in this.promptQueries) {
        if (this.promptQueries[item].isHover) {
          location.href = "/search?query=" + this.promptQueries[item].query;
        }
      }
      for (let item in this.popularQueries) {
        if (this.popularQueries[item].isHover) {
          location.href = "/search?query=" + this.popularQueries[item].value;
        }
      }
      for (let item in this.storyQueries) {
        if (this.storyQueries[item].isHover) {
          location.href = "/search?query=" + this.storyQueries[item].value;
        }
      }
      for (let item in this.promptProducts) {
        if (this.promptProducts[item].isHover) {
          location.href = this.promptProducts[item].url;
        }
      }
      for (let item in this.popularProducts) {
        if (this.popularProducts[item].isHover) {
          location.href =
            "/catalog/" + this.popularProducts[item]["shop_page"][0]["url"];
        }
      }
      return false;
    },
  },
  watch: {
    searchValue(value) {
      this.selectPromptIndex = -1;
      this.promptProducts = [];
      this.promptQueries = [
        {
          query: value,
          value: value,
        },
      ];
      let that = this;
      setTimeout(function () {
        that.searchPrompt(value);
      }, 200);
    },
  },
};
</script>
